import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../state/store";

import { userService, upload } from "../services";
import { isNil, DATA_DECRYPTER } from "../helpers";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  const toRoute = JSON.parse(localStorage.getItem("toRoute"));
  if (isNil(toRoute)) {
    localStorage.setItem("toRoute", JSON.stringify(to));
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      localStorage.getItem("isLoggin") != null &&
      localStorage.getItem("isLoggin") != false &&
      localStorage.getItem("isLoggin") != "undefined"
    ) {
      if (!store.getters["user/user"]) {
        const userName = localStorage.getItem("username");
        const decryptedUsername = DATA_DECRYPTER(userName, "lTunes");
        const res = await userService.fetchUser(decryptedUsername);
        if (
          !decryptedUsername ||
          res.Data.UserName.toLowerCase() != decryptedUsername.toLowerCase() ||
          res.errorMessage == "user-fatal-error"
        ) {
          next("/auth");
        } else {
          const tmpData = res;
          if (tmpData.Data.UserPictureId != 0) {
            const image = await upload.fetchProfileImage(
              parseInt(tmpData.Data.UserPictureId)
            );
            if (image.Data.DownloadBinary) {
              tmpData.Data.UserProfileImage = image.Data.DownloadBinary;
            } else {
              tmpData.Data.UserProfileImage = null;
            }
          } else {
            tmpData.Data.UserProfileImage = null;
          }
          store.dispatch("user/setUser", tmpData);
        }
      }
      if (
        store.getters["user/user"] &&
        store.getters["user/user"].Data.IsYSFirm
      ) {
        if (
          !to.matched.some((record) => record.meta.onlyRegistered) &&
          store.getters["user/user"] &&
          !store.getters["user/user"].Data.IsVisibleAdminPanel
        ) {
          next("/");
        } else {
          next();
        }
      } else {
        next("/auth");
      }
    } else {
      if (to.path.includes('mindo') && to.query?.question){
        localStorage.setItem("mindoQuestion", JSON.stringify(to.query.question));
      }        
      next("/login");
    }
  } else {
    next();
  }
});
router.afterEach((to) => {
  document.title = `FİKİR PLATFORMU - ${to.meta.title ? to.meta.title : ""}`;
});

export default router;
