import { authService } from "../index";

const setCookie = (name, value, exdate) => {
  //If exdate exists then pass it as a new Date and convert to UTC format
  exdate && (exdate = new Date(exdate).toUTCString());
  var c_value =
    escape(value) +
    (exdate === null || exdate === undefined ? "" : "; expires=" + exdate);
  document.cookie = name + "=" + c_value;
};

export const refreshToken = async () => {
  const res = await authService.fetchToken();
  // localStorage.setItem("token", res.Data.Token);
  // localStorage.setItem("ExpireDate", res.Data.ExpireDate);
  setCookie("token", res.Data.Token, res.Data.ExpireDate);
};
