/**
 * 
 * @param {string | number} id 
 * @param {string} location 
 * @returns string
 */
export const suggestionStatus = (id, location = "evaluation-invendo") => {
  return id == "1" && location == "evaluation-invendo"
    ? "Dağıtıcıya Yönlendirildi"
    : id == "1" && location == "productization"
    ? "Ön Değerlendirme"
    : id == "1" && location == "otoProduct"
    ? "Çalışıyor"
    : id == "2" && location == "evaluation-invendo"
    ? "Değerlendiriciye Yönlendirildi"
    : id == "2" && location == "productization"
    ? "Değerlendirme"
    : id == "2" && location == "otoProduct"
    ? "Süreç Yönetimi Ekibine Yönlendirildi"
    : id == "3" && location == "evaluation-invendo"
    ? "Karar Vericiye Yönlendirildi"
    : id == "3" && (location == "productization" || location == "otoProduct")
    ? "MvP/PoC"
    : id == "4" && location == "evaluation-invendo"
    ? "Red Edildi"
    : id == "4" && (location == "productization" || location == "otoProduct")
    ? "Kabul Edilmedi"
    : id == "5"
    ? "Invendoya Yönlendirildi"
    : id == "6" && location == "evaluation-invendo"
    ? "Onaylandı"
    : id == "6" && (location == "productization" || location == "otoProduct")
    ? "Canlı"
    : id == "7"
    ? "Silindi"
    : id == "8"
    ? "Hayata Geçti"
    : id == "9" && location != "otoProduct"
    ? "Beklemede"
    : id == "9" && location == "otoProduct"
    ? "Ertelendi"
    : id == "10"
    ? "İptal Edildi"
    : "";
};
