import { suggestionService, upload } from "../../services";
import { updateDate } from "../../helpers";
import store from "../store";
export const suggestionNewests = {
  namespaced: true,
  state: {
    newestsData: [],
    totalRecords: null,
    pageIndex: 0,
    newestsIdData: [],
  },
  getters: {
    newestsData(state) {
      return state.newestsData;
    },
    totalRecords(state) {
      return state.totalRecords;
    },
    pageIndex(state) {
      return state.pageIndex;
    },
    newestsIdData(state) {
      return state.newestsIdData;
    },
  },
  mutations: {
    async SET_DATA(state, payload) {
      for await (const element of payload) {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        for await (const comment of element.Comments) {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        }
        for await (const item of element.Likes) {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        }
      }

      state.newestsData.push(...payload);
    },
    SET_TOTAL_RECORDS(state, payload) {
      state.totalRecords = payload;
    },
    SET_PAGE_INDEX(state, payload) {
      state.pageIndex = payload;
    },
    DELETE_DATA(state) {
      state.newestsData = [];
      state.pageIndex = 0;
    },
    async SET_ID_DATA(state, payload) {
      for await (const element of [payload]) {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        for await (const comment of element.Comments) {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        }
        for await (const item of element.Likes) {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        }
      }

      state.newestsIdData.push(payload);
    },
  },
  actions: {
    async setData(vuexContext) {
      const pageSize = 5;
      const body = {
        PageIndex: suggestionNewests.state.pageIndex,
        PageSize: pageSize,
        LoginUserId: store.getters["user/user"].Data.UserId,
        EndUser: true
      };
      const res = await suggestionService.fetchPagedSuggestion(body);
      res.Data && vuexContext.commit("SET_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit("SET_TOTAL_RECORDS", res.Data.TotalRecords);
    },
    setPageIndex(vuexContext, data) {
      vuexContext.commit("SET_PAGE_INDEX", data);
    },
    deleteNewestsData(vuexContext) {
      vuexContext.commit("DELETE_DATA");
    },
    async setIdData(vuexContext, data) {
      const res = await suggestionService.fetchGetByIdSuggestion(data);
      vuexContext.commit("SET_ID_DATA", res.Data);
    },
  },
};
