import { notiflowService } from "../../services";
export const notiflow = {
  namespaced: true,
  state: {
    sendOTP: null,
    loginOTP: null,
  },
  getters: {
    getSendOTP(state) {
      return state.sendOTP;
    },
    getLoginOTP(state) {
      return state.loginOTP;
    },
  },
  mutations: {
    SET_SEND_OTP(state, payload) {
      state.sendOTP = payload;
    },
    SET_LOGIN_OTP(state, payload) {
      state.loginOTP = payload;
    },
  },
  actions: {
    async setSendOTP(vuexContext, data) {
      const res = await notiflowService.fetchSendOTP(data);
      vuexContext.commit("SET_SEND_OTP", res);
      return res;
    },
    async setLoginWithOTP(vuexContext, data) {
      const res = await notiflowService.fetchLoginWithOTP(data);
      vuexContext.commit("SET_LOGIN_OTP", res);
      return res;
    },
    async turkuazLogin(vuexContext, data) {
      const res = await notiflowService.fetchTurkuazLogin(data);
      return res;
    },
  },
};
