export const sortDate = (data, sorting) => {
  if (sorting == "ascending") {
    data.sort(function(a, b) {
      return new Date(a.CreatedOnUtc) - new Date(b.CreatedOnUtc);
    });
  } else {
    data.sort(function(a, b) {
      return new Date(b.CreatedOnUtc) - new Date(a.CreatedOnUtc);
    });
  }
};
