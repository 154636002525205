import { suggestionService, upload } from "../../services";
import store from "../store";
export const mostSuggestionUsers = {
  namespaced: true,
  state: {
    mostSuggestionUsersData: null,
  },
  getters: {
    mostSuggestionUsersData(state) {
      return state.mostSuggestionUsersData;
    },
  },
  mutations: {
    async SET_MOST_SUGGESTION_USERS(state, payload) {
      for await (let element of payload) {
        if (element.UserPictureId != 0) {
          const img = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (img.Data.DownloadBinary) {
            element.UserProfileImage = img.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
      }
      state.mostSuggestionUsersData = payload;
    },
  },
  actions: {
    async setMostSuggestionUsers(vuexContext) {
      const loginUserId = store.getters["user/user"].Data.UserId;
      const res = await suggestionService.fetchMostSuggestionUsers(loginUserId);
      vuexContext.commit("SET_MOST_SUGGESTION_USERS", res.Data.Data);
    },
  },
};
