//import store from "../state/store";
import { lowerCaseKeys } from "../helpers";
export default [
  {
    path: "/",
    name: "HomePage",
    component: () => import("../views/Main/HomePage"),
    meta: {
      title: "Anasayfa",
      requiresAuth: true,
      onlyRegistered: true,
    },
  },
  {
    path: "/contactus",
    name: "ContactUs",
    component: () => import("../views/ContactUs"),
    meta: {
      title: "İletişim",
      requiresAuth: true,
      onlyRegistered: true,
    },
  },
  {
    path: "/termofuse",
    name: "TermUse",
    component: () => import("../views/TermUse"),
    meta: {
      title: "Kullanım Şartları ve Gizlilik",
      requiresAuth: true,
      onlyRegistered: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login"),
    meta: {
      title: "Giriş",
      requiresAuth: false,
      onlyRegistered: false,
    },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("isLoggin")) {
        const toRouteStorage = JSON.parse(localStorage.getItem("toRoute"));
        if (lowerCaseKeys(toRouteStorage?.query)?.suggestionid) {
          next({
            path: toRouteStorage.path,
            query: {
              suggestionid: lowerCaseKeys(toRouteStorage.query).suggestionid,
            },
          });
        } else if (lowerCaseKeys(toRouteStorage?.query)?.productid) {
          next({
            path: toRouteStorage.path,
            query: {
              productid: lowerCaseKeys(toRouteStorage.query).productid,
            },
          });
        } else if (toRouteStorage?.params) {
          next({
            path: toRouteStorage.path,
            params: { id: toRouteStorage.params },
          });
        } else {
          next();
        }
      } else {
        next();
      }
    },
  },
  {
    path: "/FrequentlyAskedQuestion",
    name: "AskedQuestion",
    component: () => import("../views/Main/AskedQuestion"),
    meta: {
      title: "Sıkça Sorulan Sorular",
      requiresAuth: true,
      onlyRegistered: true,
    },
  },
  {
    path: "/user",
    name: "AccountInfo",
    component: () => import("../views/Main/AccountInfo"),
    meta: {
      title: "Profil Bilgileri",
      requiresAuth: true,
      onlyRegistered: true,
    },
  },
  {
    path: "/mindo",
    name: "Mindo",
    component: () => import("../views/Main/Mindo"),
    meta: {
      title: "MİNDO",
      requiresAuth: true,
      onlyRegistered: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.query?.question){
        localStorage.setItem("mindoQuestion", JSON.stringify(to.query.question));
        next()
      }else {
        const mindoQuestion = JSON.parse(localStorage.getItem("mindoQuestion"));
        if (to.query['question'] == mindoQuestion){
          next()
        }
        else{
          if (mindoQuestion) {
            next({
              ...to,
              query: {
                question: mindoQuestion
              }
            });
          } else {
            next();
          }
        }
      }
      
    }     
  },
  {
    path: "/admin",
    name: "Admin",
    redirect: "/admin/suggestions",
    // beforeEnter: (to, from, next) => {
    //   to.path === "/admin" ? next({ name: "Evaluation" }) : next();
    // },
  },
  {
    path: "/admin/suggestions",
    name: "Evaluation",
    component: () => import("../views/Admin/Evaluation"),
    meta: {
      title: "Fikirleriniz",
      requiresAuth: true,
      onlyRegistered: false,
    },
  },
  /*
  {
    path: "/admin/productization",
    name: "Productization",
    component: () => import("../views/Admin/Productization"),
    meta: {
      title: "Fikirleriniz",
      requiresAuth: true,
      onlyRegistered: false,
    },
    beforeEnter: (to, from, next) => {
      //TODO Productization icin userRoles kaldirildi
      if (store.getters["user/user"]) {
        next();
      } else {
        next({ name: "Admin" });
      }
    },
  },
  */
  {
    path: "/admin/notify",
    name: "ReportProblem",
    component: () => import("../views/Admin/ReportProblem"),
    meta: {
      title: "Sorun Bildir",
      requiresAuth: true,
      onlyRegistered: false,
    },
  },
  {
    path: "/suggestion",
    name: "Suggestion",
    redirect: "/suggestion/idea",
    onlyRegistered: true,
  },
  {
    path: "/suggestion/idea",
    name: "Idea",
    component: () => import("../views/MyIdea/Idea"),
    meta: {
      title: "Fikir",
      requiresAuth: true,
      onlyRegistered: true,
    },
  },
  // {
  //   path: "/suggestion/invendo",
  //   name: "IdeaInvendo",
  //   component: () => import("../views/MyIdea/Invendo"),
  //   meta: {
  //     title: "Fikir",
  //     requiresAuth: true,
  //     onlyRegistered: true,
  //   },
  // },
  // {
  //   path: "/suggestion/productidea",
  //   name: "ProductIdea",
  //   component: () => import("../views/MyIdea/ProductIdea"),
  //   meta: {
  //     title: "Fikir",
  //     requiresAuth: true,
  //     onlyRegistered: true,
  //   },
  // },
  // {
  //   path: "/suggestion/teamlead",
  //   name: "Teamlead",
  //   component: () => import("../views/MyIdea/TeamLead"),
  //   meta: {
  //     title: "Ekip Lideri",
  //     requiresAuth: true,
  //     onlyRegistered: true,
  //   },
  //   beforeEnter: (to, from, next) => {
  //     if (store.getters["user/user"]) {
  //       next();
  //     } else {
  //       next({ name: "Suggestion" });
  //     }
  //   },
  // },
  // {
  //   path: "/suggestion/myassignments",
  //   name: "Myassignments",
  //   component: () => import("../views/MyIdea/MyAssignments"),
  //   meta: {
  //     title: "Atandıklarım",
  //     requiresAuth: true,
  //     onlyRegistered: true,
  //   },
  //   beforeEnter: (to, from, next) => {
  //     if (store.getters["user/user"]) {
  //       next();
  //     } else {
  //       next({ name: "Suggestion" });
  //     }
  //   },
  // },
  {
    path: "/suggestion/draftidea",
    name: "DraftIdea",
    component: () => import("../views/MyIdea/DraftIdea"),
    meta: {
      title: "Taslaklarım",
      requiresAuth: true,
      onlyRegistered: true,
    },
  },
  // {
  //   path: "/suggestion/draftinvendo",
  //   name: "DraftInvendo",
  //   component: () => import("../views/MyIdea/DraftInvendo"),
  //   meta: {
  //     title: "Taslaklarım",
  //     requiresAuth: true,
  //     onlyRegistered: true,
  //   },
  // },
  // {
  //   path: "/suggestion/draftproduct",
  //   name: "DraftProduct",
  //   component: () => import("../views/MyIdea/DraftProduct"),
  //   meta: {
  //     title: "Taslaklarım",
  //     requiresAuth: true,
  //     onlyRegistered: true,
  //   },
  // },
  {
    path: "/search",
    redirect: "/search/idea",
    onlyRegistered: true,
  },
  {
    path: "/search/idea",
    name: "SearchIdea",
    component: () => import("../views/IdeaSearch/Idea"),
    meta: {
      title: "Fikirlerim",
      requiresAuth: true,
      onlyRegistered: true,
    },
  },
  {
    path: "/search/productidea",
    name: "SearchIdeaProduct",
    component: () => import("../views/IdeaSearch/ProductIdea"),
    meta: {
      title: "Fikirlerim",
      requiresAuth: true,
      onlyRegistered: true,
    },
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/Auth"),
    meta: {
      title: "Yetkilendirme Hatası",
    },
  },
  {
    path: "/project-pool",
    name: "ProjectPoll",
    component: () => import("../views/Project/ProjectPool"),
    meta: {
      title: "Proje Havuzu",
      requiresAuth: true,
      onlyRegistered: true,
    },
  },
  {
    path: "/*",
    name: "NotFound",
    component: () => import("../views/NotFound"),
    meta: {
      title: "Sayfa Bulunamadı",
    },
  },
];
