import { suggestionService, upload } from "../../services";
import { updateDate } from "../../helpers";
import store from "../store";
export const mostLikedSuggestions = {
  namespaced: true,
  state: {
    mostLikedData: [],
    totalRecords: null,
    pageIndex: 0,
  },
  getters: {
    pageData(state) {
      return state.mostLikedData;
    },
    totalRecords(state) {
      return state.totalRecords;
    },
    pageIndex(state) {
      return state.pageIndex;
    },
  },
  mutations: {
    async SET_DATA(state, payload) {
      for await (const element of payload) {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }

        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );

        for await (const comment of element.Comments) {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        }
        for await (const item of element.Likes) {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        }
      }

      state.mostLikedData.push(...payload);
    },
    SET_TOTAL_RECORDS(state, payload) {
      state.totalRecords = payload;
    },
    SET_PAGE_INDEX(state) {
      state.pageIndex++;
    },
    DELETE_DATA(state) {
      state.mostLikedData = [];
      state.pageIndex = 0;
    },
  },
  actions: {
    async setData(vuexContext) {
      const res = await suggestionService.fetchMostLikedSuggestions(
        store.getters["user/user"].Data.CompanyId,
        store.getters["user/user"].Data.GroupCompanyId
      );
      res.Data && vuexContext.commit("SET_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit("SET_TOTAL_RECORDS", res.Data.TotalRecords);
    },
    setPageIndex(vuexContext) {
      vuexContext.commit("SET_PAGE_INDEX");
    },
    deleteMostLikedData(vuexContext) {
      vuexContext.commit("DELETE_DATA");
    },
  },
};
