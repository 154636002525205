import { BASE_URL, responseHandler, bearerAuthHeader } from "./helpers";

export const suggestionService = {
  fetchCreateSuggesiton,
  fetchCurrent,
  fetchPagedSuggestion,
  fetchCreateLike,
  fetchDeleteLike,
  fetchCreateComment,
  fetchDeleteComment,
  fetchUpdateComment,
  fetchDeleteSuggestion,
  fetchUpdateSuggestion,
  fetchGetUsers,
  fetchCreateSupport,
  fetchMostSuggestionUsers,
  fetchMostLikedSuggestions,
  fetchCompanies,
  fetchGetStatusByRoles,
  fetchGetFilterStatusByRoles,
  fetchGetStatusByCustomerRoles,
  fetchGetManagerByValueCurrentId,
  fetchSliderPicture,
  fetchGetCancelReasons,
  fetchGetByIdSuggestion,
  fetchSuggestionDownloadExcel,
  fetchCreateNotify,
};

async function fetchCreateSuggesiton(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/createsuggestion`,
    requestOptions
  );

  return responseHandler(result);
}

async function fetchCurrent(companyId, companyGroupId) {
  const formData = new FormData();
  if (companyGroupId > 0) formData.append("companyGroupId", companyGroupId);
  else formData.append("companyId", companyId);

  const requestOptions = {
    method: "POST",
    headers: {
      ...(await bearerAuthHeader()),
    },
    body: formData,
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/GetValueCurrents`,
    requestOptions
  );

  return responseHandler(result);
}

async function fetchPagedSuggestion(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/getpagedsuggestion`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchCreateLike(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/createlike`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchDeleteLike(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/deletelike`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchCreateComment(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/createcomment`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchDeleteComment(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/deletecomment`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchUpdateComment(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/updatecomment`,
    requestOptions
  );

  return responseHandler(result);
}

async function fetchDeleteSuggestion(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...(await bearerAuthHeader()),
    },
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/deletesuggestion/${id}`,
    requestOptions
  );

  return responseHandler(result);
}

async function fetchUpdateSuggestion(body) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/updatesuggestion`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchGetUsers(companyId) {
  const formData = new FormData();
  formData.append("companyId", companyId);

  const requestOptions = {
    method: "POST",
    headers: {
      ...(await bearerAuthHeader()),
    },
    body: formData,
    redirect: "follow",
  };
  const result = await fetch(`${BASE_URL}/suggestion/getusers`, requestOptions);

  return responseHandler(result);
}
async function fetchCreateSupport(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/createsupport`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchMostSuggestionUsers(loginUserId) {
  const formData = new FormData();
  formData.append("loginUserId", loginUserId);

  const requestOptions = {
    method: "POST",
    headers: {
      ...(await bearerAuthHeader()),
    },
    body: formData,
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/mostsuggestionusers`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchMostLikedSuggestions(companyId, companyGroupId) {
  const formData = new FormData();
  formData.append("GroupCompanyId", companyGroupId);
  formData.append("CompanyId", companyId);

  const requestOptions = {
    method: "POST",
    headers: {
      ...(await bearerAuthHeader()),
    },
    body: formData,
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/mostlikedsuggestions`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchCompanies(loginUserId) {
  const formData = new FormData();
  formData.append("loginUserId", loginUserId);

  const requestOptions = {
    method: "POST",
    headers: {
      ...(await bearerAuthHeader()),
    },
    body: formData,
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/GetCompaniesByLoginUserId`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchGetStatusByRoles(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/getcompanystatus`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchGetFilterStatusByRoles(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/getcompanyfilterstatus`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchGetStatusByCustomerRoles(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/getStatusListByCustomer`,
    requestOptions
  );

  return responseHandler(result);
}

async function fetchGetManagerByValueCurrentId(
  companyId,
  valueCurrentId,
  isEvaluator,
  isDecider,
  typeId
) {
  const formData = new FormData();
  formData.append("companyId", companyId);
  formData.append("valueCurrentId", valueCurrentId);
  formData.append("isEvaluator", isEvaluator);
  formData.append("isDecider", isDecider);
  formData.append("typeId", typeId);

  const requestOptions = {
    method: "POST",
    headers: {
      ...(await bearerAuthHeader()),
    },
    body: formData,
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/GetManagerByValueCurrentId`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchSliderPicture(companyId) {
  const formData = new FormData();
  formData.append("CompanyId", companyId);
  const requestOptions = {
    method: "POST",
    headers: {
      ...(await bearerAuthHeader()),
    },
    body: formData,
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/sliderpicturebycompanyid`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchGetCancelReasons(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...(await bearerAuthHeader()),
    },
    body: body,
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/getcancelreasons`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchGetByIdSuggestion(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...(await bearerAuthHeader()),
    },
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/getsuggestionbyid/${id}`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchSuggestionDownloadExcel(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/suggestionexcelexport`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchCreateNotify(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestion/createnotify`,
    requestOptions
  );

  return responseHandler(result);
}