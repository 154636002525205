import { BASE_URL, responseHandler, bearerAuthHeader } from "./helpers";

export const suggestionInvendoService = {
  fetchCreateSuggestionInvendo,
  fetchInvendoPagedSuggestion,
  fetchUpdateSuggestionInvendo,
  fetchDeleteSuggestionInvendo,
};

async function fetchCreateSuggestionInvendo(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(
    `${BASE_URL}/suggestioninvendo/createsuggestioninvendo`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchInvendoPagedSuggestion(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/sugestioninvendo/pagedsuggestioninvendo`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchUpdateSuggestionInvendo(body) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestioninvendo/updatesuggestioninvendo`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchDeleteSuggestionInvendo(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...(await bearerAuthHeader()),
    },
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestioninvendo/deletesuggestioninvendo/${id}`,
    requestOptions
  );

  return responseHandler(result);
}