export const live = {
  namespaced: true,
  state: {
    user: null,
    isAuthenticated: false,
    isSignOut: false,
  },
  getters: {
    user(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    isSignOut(state) {
      return state.isSignOut;
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_AUTHENTICATED(state, payload) {
      state.isAuthenticated = payload;
    },
    SET_SIGNOUT(state, payload) {
      state.isSignOut = payload;
    },
  },
  actions: {
    setUser(vuexContext, data) {
      vuexContext.commit("SET_USER", data);
    },
    setIsAuthenticated(vuexContext, data) {
      vuexContext.commit("SET_AUTHENTICATED", data);
    },
    setSignOut(vuexContext, data) {
      vuexContext.commit("SET_SIGNOUT", data);
    },
  },
};
