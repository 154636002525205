import { BASE_URL, responseHandler, bearerAuthHeader } from "./helpers";

export const mindoService = {
  fetchGetMindoQuestions,
  fetchGetSuggestions,
  fetchCreateMindo,
  fetchUpdateMindo,
  fetchDeleteMindo,
  fetchMostLikedMindos,
  fetchMostCommentedMindos,
};

async function fetchGetMindoQuestions(companyId) {
  const body = {
    CompanyId: companyId,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(
    `${BASE_URL}/mindo/GetMindoQuestionsByCompanyId`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchGetSuggestions(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(
    `${BASE_URL}/mindo/getmindosbymindoquestionidandcompanyid`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchCreateMindo(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(`${BASE_URL}/mindo/create`, requestOptions);

  return responseHandler(result);
}
async function fetchUpdateMindo(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(`${BASE_URL}/mindo/update`, requestOptions);

  return responseHandler(result);
}
async function fetchDeleteMindo(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(`${BASE_URL}/mindo/delete`, requestOptions);

  return responseHandler(result);
}
async function fetchMostLikedMindos(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...(await bearerAuthHeader()),
    },
    body: body,
  };
  const result = await fetch(
    `${BASE_URL}/mindo/mostlikedmindos`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchMostCommentedMindos(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...(await bearerAuthHeader()),
    },
    body: body,
  };
  const result = await fetch(
    `${BASE_URL}/mindo/mostcommentedmindos`,
    requestOptions
  );

  return responseHandler(result);
}
