export const loading = {
  namespaced: true,
  state: {
    isLoading: false,
  },
  getters: {
    getIsLoading(state) {
      return state.isLoading;
    },
  },
  mutations: {
    async SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    async setIsLoading(vuexContext, data) {
      vuexContext.commit("SET_LOADING", data);
    },
  },
};
