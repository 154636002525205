import { faqService } from "../../services";
import store from "../store";
export const faq = {
  namespaced: true,
  state: {
    faqList: [],
  },
  getters: {
    getFaqList(state) {
      return state.faqList;
    },
  },
  mutations: {
    SET_FAQ_LIST(state, payload) {
      state.faqList = payload;
    },
  },
  actions: {
    async setFaqList(vuexContext) {
      const res = await faqService.fetchFaqList({
        CompanyId: store.getters["user/user"].Data.CompanyId,
        GroupCompanyId: store.getters["user/user"].Data.GroupCompanyId,
      });
      vuexContext.commit("SET_FAQ_LIST", res.Data);
    },
  },
};
