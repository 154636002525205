import { BASE_URL, responseHandler } from "./helpers";
import { DATA_DECRYPTER } from "../helpers";

export const authService = {
  fetchToken,
};

async function fetchToken() {
  const userName = localStorage.getItem("username");
  const decryptedUsername = DATA_DECRYPTER(userName, "lTunes");
  const body = {
    SecretKey: "inovasyonplatformuapiusername",
    SecretValue: "inovasyonplatformuapipass",
    UserName: decryptedUsername,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...body }),
  };

  const result = await fetch(`${BASE_URL}/auth/requesttoken`, requestOptions);
  return responseHandler(result);
}
