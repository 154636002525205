import { encode } from "base-64";
import { refreshToken } from ".";

export const cookieToken = () => {
  if (document.cookie.split("; ").find((row) => row.startsWith("token=")))
    return document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      .split("=")[1];
  else return null;
};

export const bearerAuthHeader = async () => {
  let token = null;
  //Has the token expired? CONTROLL
  if (cookieToken()) {
    token = cookieToken();
  } else {
    // localStorage.removeItem("ExpireDate");
    // localStorage.removeItem("token");
    await refreshToken();
    token = cookieToken();
  }
  if (token) {
    return {
      Authorization: "Bearer " + token,
    };
  } else {
    return null;
  }
};

export const basicAuthHeader = () => {
  const username = "inovasyonplatformuapiusername";
  const password = "inovasyonplatformuapipass";
  return {
    Authorization: "Basic " + encode(username + ":" + password),
  };
};
