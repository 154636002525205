import Vue from "vue";
import Vuex from "vuex";
import {
  live,
  user,
  suggestionNewests,
  mostSuggestionUsers,
  mindo,
  suggestionRD,
  loading,
  mostLikedSuggestions,
  ideas,
  suggestion,
  draft,
  faq,
  projectPool,
  notiflow,
} from "./modules";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    live: live,
    user: user,
    suggestionNewests: suggestionNewests,
    mostSuggestionUsers: mostSuggestionUsers,
    mindo: mindo,
    suggestionRD: suggestionRD,
    loading: loading,
    mostLiked: mostLikedSuggestions,
    ideas: ideas,
    suggestion: suggestion,
    draft: draft,
    faq: faq,
    projectPool: projectPool,
    notiflow: notiflow,
  },
});
