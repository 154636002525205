/**
 * 
 * @param {string} type 
 * @returns string
 */
export const fileTypeCheck = (type) => {
  if (type == null) return null;
  else {
    if (type == "jpeg") return "image/jpeg";
    else if (type == "jpg") return "image/jpg";
    else if (type == "png") return "image/png";
    else if (type == "gif") return "image/gif";
    else if (type == "msword") return "application/msword";
    else if (
      type == "vnd.openxmlformats-officedocument.wordprocessingml.document"
    )
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    else if (type == "vnd.ms-powerpoint")
      return "application/vnd.ms-powerpoint";
    else if (
      type == "vnd.openxmlformats-officedocument.presentationml.presentation"
    )
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    else if (type == "vnd.ms-excel") return "application/vnd.ms-excel";
    else if (type == "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    else if (type == "x-zip-compressed") return "application/x-zip-compressed";
    else if (type == "zip") return "application/zip";
    else if (type == "pdf") return "application/pdf";
    else return null;
  }
};
