import { BASE_URL, responseHandler, bearerAuthHeader } from "./helpers";

export const suggestionRdService = {
  fetchCreateSuggesitonRD,
  fetchPagedSuggesitonRD,
  fetchUpdateSuggestionRD,
  fetchGetDepartments,
  fetchDeleteSuggestionRD,
  fetchGetByIdSuggestion,
  fetchSuggestionRDDownloadExcel,
};

async function fetchGetByIdSuggestion(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...(await bearerAuthHeader()),
    },
  };
  const result = await fetch(
    `${BASE_URL}/suggestionrd/getsuggestionrdbyid/${id}`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchCreateSuggesitonRD(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(
    `${BASE_URL}/suggestionrd/createsuggestionrd`,
    requestOptions
  );

  return responseHandler(result);
}

async function fetchPagedSuggesitonRD(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(
    `${BASE_URL}/suggestionrd/pagedsuggestionrd`,
    requestOptions
  );

  return responseHandler(result);
}

async function fetchUpdateSuggestionRD(body) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestionrd/updatesuggestionrd`,
    requestOptions
  );

  return responseHandler(result);
}

async function fetchGetDepartments(companyId, companyGroupId) {
  const body = {
    CompanyId: companyId,
    CompanyGroupId: companyGroupId,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestionrd/GetDepartments`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchDeleteSuggestionRD(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...(await bearerAuthHeader()),
    },
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestionrd/deletesuggestionrd/${id}`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchSuggestionRDDownloadExcel(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
    redirect: "follow",
  };
  const result = await fetch(
    `${BASE_URL}/suggestionrd/suggestionrdexcelexport`,
    requestOptions
  );

  return responseHandler(result);
}
