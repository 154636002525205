import moment from "moment";
export const dateFormatter = (date) => {
  if (date == null) return null;
  else {
    const a = date.split("T");
    const b = a[0];
    const c = b.split("-");
    const d = a[1].split(":");
    const dateStr = `${c[2]}.${c[1]}.${c[0]} ${d[0]}:${d[1]}:${c[2]}`;

    return dateStr;
  }
};
export const newDateFormatter = (date) => {
  if (date == null) return null;
  else {
    const newDate = new Date(date)
    const dateStr =
      ("00" + newDate.getDate()).slice(-2) +
      "." +
      ("00" + (newDate.getMonth() + 1)).slice(-2) +
      "." +
      newDate.getFullYear() +
      " " +
      ("00" + newDate.getHours()).slice(-2) +
      ":" +
      ("00" + newDate.getMinutes()).slice(-2) +
      ":" +
      ("00" + newDate.getSeconds()).slice(-2);
    return dateStr;
  }
};
export const customDateFormatter = (date) => {
  if (date == null) return null;
  else {
    const newDate = new Date(date)
    const dateStr =
      newDate.getFullYear() +
      "." +
      ("00" + (newDate.getMonth() + 1)).slice(-2) +
      "." +
      ("00" + newDate.getDate()).slice(-2)
    return dateStr;
  }
};
export const updateDate = (date) => {
  if (!date) return null;
  else
    return moment(date).format();
};

export const dateDiff = (m1, m2 = new Date()) => {
  const diff = moment.preciseDiff(m1, m2, true);
  if (diff.years != 0) return diff.years + " yıl";
  else if (diff.months != 0) return diff.months + " ay";
  else if (diff.days != 0) return diff.days + " gün";
  else if (diff.hours != 0) return diff.hours + " saat";
  else if (diff.minutes != 0) return diff.minutes + " dk";
  else return diff.seconds + " sn";
};
export const currentDateTime = () => {
  return moment().format()
}