import { BASE_URL, responseHandler, bearerAuthHeader } from "./helpers";

import { DATA_DECRYPTER } from "../helpers";

export const userService = {
  fetchUser,
};

async function fetchUser(userName) {
  const userLocalName = localStorage.getItem("username");
  const decryptedUsername = DATA_DECRYPTER(userLocalName, "lTunes");

  if (decryptedUsername == userName) {
    const formData = new FormData();
    formData.append("userName", userName);
    const requestOptions = {
      method: "POST",
      headers: {
        ...(await bearerAuthHeader()),
      },
      body: formData,
      redirect: "follow",
    };
    const result = await fetch(
      `${BASE_URL}/suggestion/getuserbyusername`,
      requestOptions
    );
    return responseHandler(result);
  } else {
    return {
      errorMessage: "user-fatal-error",
    };
  }
}