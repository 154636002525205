import { BASE_URL, responseHandler, bearerAuthHeader } from "./helpers";

export const faqService = {
  fetchFaqList,
};
async function fetchFaqList(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(
    `${BASE_URL}/faq/getallfaqs`,
    requestOptions
  );

  return responseHandler(result);
}