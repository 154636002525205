import { upload, suggestionRdService } from "../../services";
import { updateDate } from "../../helpers";
import store from "../store";
export const projectPool = {
  namespaced: true,
  state: {
    dataProjectPool: [],
    projectPoolTotalRecords: null,
  },
  getters: {
    getProjectPoolData(state) {
      return state.dataProjectPool;
    },
    projectPoolTotalRecords(state) {
      return state.projectPoolTotalRecords;
    },
  },
  mutations: {
    async SET_PROJECT_POOL_DATA(state, payload) {
      payload.forEach(async (element) => {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.SuggestionRDUserModelsValueCurrentManager = []
        element.SuggestionRDUserModelsTeamLead = [];
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
        element.SuggestionRDUserModels.forEach(async (suggestion) => {
          switch (suggestion.UserStatus) {
            case 4:
              element.SuggestionRDUserModelsValueCurrentManager.push(
                suggestion.UserName
              );
              break;
            case 3:
              element.SuggestionRDUserModelsTeamLead.push(suggestion.UserName);
              break;

            default:
              break;
          }
        });
      });
      state.dataProjectPool.push(...payload);
    },
    SET_PROJECT_POOL_TOTAL_RECORDS(state, payload) {
      state.projectPoolTotalRecords = payload;
    },
    DELETE_PROJECT_POOL_DATA(state) {
      state.dataProjectPool = [];
    },
  },
  actions: {
    async setProjectPoolData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        searchKeyword,
        suggestionTypes,
        companyId,
        groupCompanyId,
        suggestionNumber,
        valueCurrentId,
        suggestionStatuses,
        createdUserId,
        loginUserId,
        startDate,
        endDate,
        suggestionEvaluatorId,
        suggestionDeciderId,
        department,
        departmentGeneral,
        valueCurrentManagerId,
        title,
        projectYear,
        suggestionTeamLeaderId,
        teamLeaderId,
      } = data;
      const body = {
        Page: pageIndex,
        PageSize: pageSize,
        SearchKeyword: searchKeyword,
        SuggestionTypes: suggestionTypes,
        CompanyId: companyId,
        CompanyGroupId: groupCompanyId,
        SuggestionNumber: suggestionNumber,
        ValueCurrentId: valueCurrentId,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        LoginUserId: loginUserId,
        StartDate: startDate,
        EndDate: endDate,
        IsAssignment: false,
        SuggestionEvaluatorId: suggestionEvaluatorId,
        SuggestionDeciderId: suggestionDeciderId,
        Department: department,
        DepartmentGeneral: departmentGeneral,
        ValueCurrentManagerId: valueCurrentManagerId,
        Title: title,
        ProjectYear: projectYear,
        SuggestionTeamLeaderId: suggestionTeamLeaderId,
        TeamLeaderId: teamLeaderId,
        IsSharePoint: true,
      };
      const res = await suggestionRdService.fetchPagedSuggesitonRD(body);
      vuexContext.commit("SET_PROJECT_POOL_DATA", res.Data.Data);
      vuexContext.commit(
        "SET_PROJECT_POOL_TOTAL_RECORDS",
        res.Data.TotalRecords
      );
    },
    deleteProductData(vuexContext) {
      vuexContext.commit("DELETE_PROJECT_POOL_DATA");
    },
  },
};
