import { mindoService, upload } from "../../services";
import { updateDate } from "../../helpers";
import store from "../store";
export const mindo = {
  namespaced: true,
  state: {
    mindoQuestions: null,
    suggestionsList: [],
    suggestionTotalRecords: null,
    mostLikedList: [],
    mostCommentedList: [],
  },
  getters: {
    getMindoQuestions(state) {
      return state.mindoQuestions;
    },
    getSuggestions(state) {
      return state.suggestionsList;
    },
    suggestionTotalRecords(state) {
      return state.suggestionTotalRecords;
    },
    getMostLiked(state) {
      return state.mostLikedList;
    },
    getMostCommented(state) {
      return state.mostCommentedList;
    },
  },
  mutations: {
    async SET_MINDO_QUESTIONS(state, payload) {
      state.mindoQuestions = payload;
    },
    async SET_SUGGESTION_LIST(state, payload) {
      payload.forEach(async (element) => {
        if (element.UserPictureId && element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        if (element.Comments && element.Comments.length > 0) {
          element.Comments.forEach(async (comment) => {
            comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
            if (comment.UserPictureId != 0) {
              const res = await upload.fetchProfileImage(
                parseInt(comment.UserPictureId)
              );
              if (res.Data.DownloadBinary) {
                comment.UserProfileImage = res.Data.DownloadBinary;
              } else {
                comment.UserProfileImage = null;
              }
            } else {
              comment.UserProfileImage = null;
            }
          });
        }
        if (element.Likes && element.Likes.length > 0) {
          element.Likes.forEach(async (item) => {
            if (item.UserPictureId != 0) {
              const res = await upload.fetchProfileImage(
                parseInt(item.UserPictureId)
              );
              if (res.Data.DownloadBinary) {
                item.UserProfileImage = res.Data.DownloadBinary;
              } else {
                item.UserProfileImage = null;
              }
            } else {
              item.UserProfileImage = null;
            }
            if (item.UserId == store.getters["user/user"].Data.UserId) {
              element.IsLiked = true;
            } else {
              element.IsLiked = false;
            }
          });
        }
      });
      state.suggestionsList.push(...payload);
    },
    async SET_SUGGESTION_TOTAL_RECORDS(state, payload) {
      state.suggestionTotalRecords = payload;
    },
    async DELETE_SUGGESTION_DATA(state) {
      state.suggestionsList = [];
    },
    async SET_MINDO_LIKED_LIST(state, payload) {
      payload.forEach(async (element) => {
        if (element.UserPictureId && element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        if (element.Comments && element.Comments.length > 0) {
          element.Comments.forEach(async (comment) => {
            comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
            if (comment.UserPictureId != 0) {
              const res = await upload.fetchProfileImage(
                parseInt(comment.UserPictureId)
              );
              if (res.Data.DownloadBinary) {
                comment.UserProfileImage = res.Data.DownloadBinary;
              } else {
                comment.UserProfileImage = null;
              }
            } else {
              comment.UserProfileImage = null;
            }
          });
        }
        if (element.Likes && element.Likes.length > 0) {
          element.Likes.forEach(async (item) => {
            if (item.UserPictureId != 0) {
              const res = await upload.fetchProfileImage(
                parseInt(item.UserPictureId)
              );
              if (res.Data.DownloadBinary) {
                item.UserProfileImage = res.Data.DownloadBinary;
              } else {
                item.UserProfileImage = null;
              }
            } else {
              item.UserProfileImage = null;
            }
            if (item.UserId == store.getters["user/user"].Data.UserId) {
              element.IsLiked = true;
            } else {
              element.IsLiked = false;
            }
          });
        }
      });
      state.mostLikedList.push(...payload);
    },
    async DELETE_LIKED_MINDO_DATA(state) {
      state.mostLikedList = [];
    },
    async SET_MINDO_COMMENTED_LIST(state, payload) {
      payload.forEach(async (element) => {
        if (element.UserPictureId && element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        if (element.Comments && element.Comments.length > 0) {
          element.Comments.forEach(async (comment) => {
            comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
            if (comment.UserPictureId != 0) {
              const res = await upload.fetchProfileImage(
                parseInt(comment.UserPictureId)
              );
              if (res.Data.DownloadBinary) {
                comment.UserProfileImage = res.Data.DownloadBinary;
              } else {
                comment.UserProfileImage = null;
              }
            } else {
              comment.UserProfileImage = null;
            }
          });
        }
        if (element.Likes && element.Likes.length > 0) {
          element.Likes.forEach(async (item) => {
            if (item.UserPictureId != 0) {
              const res = await upload.fetchProfileImage(
                parseInt(item.UserPictureId)
              );
              if (res.Data.DownloadBinary) {
                item.UserProfileImage = res.Data.DownloadBinary;
              } else {
                item.UserProfileImage = null;
              }
            } else {
              item.UserProfileImage = null;
            }
            if (item.UserId == store.getters["user/user"].Data.UserId) {
              element.IsLiked = true;
            } else {
              element.IsLiked = false;
            }
          });
        }
      });
      state.mostCommentedList.push(...payload);
    },
    async DELETE_COMMENTED_MINDO_DATA(state) {
      state.mostCommentedList = [];
    },
  },
  actions: {
    async setMindoQuestions(vuexContext) {
      const companyId = store.getters["user/user"].Data.CompanyId;
      const res = await mindoService.fetchGetMindoQuestions(companyId);
      vuexContext.commit("SET_MINDO_QUESTIONS", res.Data);
    },
    async setSuggestionsData(vuexContext, data) {
      const { pageSize, pageIndex, mindoQuestionId, companyId } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        MindoQuestionId: mindoQuestionId,
        CompanyId: companyId,
      };
      const res = await mindoService.fetchGetSuggestions(body);
      if (res) {
        vuexContext.commit("SET_SUGGESTION_LIST", res.Data.Data);
        vuexContext.commit(
          "SET_SUGGESTION_TOTAL_RECORDS",
          res.Data.TotalRecords
        );
      }
    },
    deleteSuggestionData(vuexContext) {
      vuexContext.commit("DELETE_SUGGESTION_DATA");
    },
    async setMostLikedMindos(vuexContext, data) {
      const { companyId } = data;
      const formdata = new FormData();
      formdata.append("companyId", companyId);
      const res = await mindoService.fetchMostLikedMindos(formdata);
      if (res) {
        vuexContext.commit("SET_MINDO_LIKED_LIST", res.Data.Data);
      }
    },
    deleteMostLikedMindos(vuexContext) {
      vuexContext.commit("DELETE_LIKED_MINDO_DATA");
    },
    async setMostCommentedMindos(vuexContext, data) {
      const { companyId } = data;
      const formdata = new FormData();
      formdata.append("companyId", companyId);
      const res = await mindoService.fetchMostCommentedMindos(formdata);
      if (res) {
        vuexContext.commit("SET_MINDO_COMMENTED_LIST", res.Data.Data);
      }
    },
    deleteMostCommentedMindos(vuexContext) {
      vuexContext.commit("DELETE_COMMENTED_MINDO_DATA");
    },
  },
};
