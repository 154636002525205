import {
  suggestionService,
  upload,
  suggestionInvendoService,
  suggestionRdService,
} from "../../services";
import { updateDate } from "../../helpers";
import store from "../store";
export const suggestion = {
  namespaced: true,
  state: {
    companiesData: null,
    valueCurrents: [],
    statusByRoles: [],
    statusByRolesInvendo: [],
    statusByRolesProductization: [],
    filterStatusByRoles: [],
    filterStatusByRolesInvendo: [],
    filterStatusByRolesProductization: [],
    statusByCustomerRoles: [],
    statusByCustomerRolesInvendo: [],
    statusByCustomerRolesProductization: [],
    evaluationData: [],
    evaluationTotalRecords: null,
    invendoData: [],
    invendoTotalRecords: null,
    productizationData: [],
    productizationTotalRecords: null,
    sliderPicture: null,
    cancelReasons: [],
  },
  getters: {
    companiesData(state) {
      return state.companiesData;
    },
    getValueCurrents(state) {
      return state.valueCurrents;
    },
    statusByRoles(state) {
      return state.statusByRoles;
    },
    statusByRolesInvendo(state) {
      return state.statusByRolesInvendo;
    },
    statusByRolesProductization(state) {
      return state.statusByRolesProductization;
    },
    filterStatusByRoles(state) {
      return state.filterStatusByRoles;
    },
    filterStatusByRolesInvendo(state) {
      return state.filterStatusByRolesInvendo;
    },
    filterStatusByRolesProductization(state) {
      return state.filterStatusByRolesProductization;
    },
    statusByCustomerRoles(state) {
      return state.statusByCustomerRoles;
    },  
    statusByCustomerRolesInvendo(state) {
      return state.statusByCustomerRolesInvendo;
    },  
    statusByCustomerRolesProductization(state) {
      return state.statusByCustomerRolesProductization;
    },            
    getEvluationData(state) {
      return state.evaluationData;
    },
    evaluationTotalRecords(state) {
      return state.evaluationTotalRecords;
    },
    getInvendoData(state) {
      return state.invendoData;
    },
    invendoTotalRecords(state) {
      return state.invendoTotalRecords;
    },
    getProductizationData(state) {
      return state.productizationData;
    },
    productizationTotalRecords(state) {
      return state.productizationTotalRecords;
    },
    getSliderPicture(state) {
      return state.sliderPicture;
    },
    getCancelReasons(state) {
      return state.cancelReasons;
    },
  },
  mutations: {
    async SET_DATA(state, payload) {
      state.companiesData = payload;
    },
    DELETE_DATA(state) {
      state.companiesData = [];
    },
    async GET_VALUE_CURRENTS_DATA(state, payload) {
      state.valueCurrents = payload;
    },
    async GET_STATUS_BY_ROLES(state, payload) {
      state.statusByRoles = payload.Data;
    },
    async GET_STATUS_BY_ROLES_INVENDO(state, payload) {
      state.statusByRolesInvendo = payload.Data;
    },
    async GET_STATUS_BY_ROLES_PRODUCTIZATION(state, payload) {
      state.statusByRolesProductization = payload.Data;
    },
    async GET_FILTER_STATUS_BY_ROLES(state, payload) {
      state.filterStatusByRoles = payload.Data;
    },
    async GET_FILTER_STATUS_BY_ROLES_INVENDO(state, payload) {
      state.filterStatusByRolesInvendo = payload.Data;
    },
    async GET_FILTER_STATUS_BY_ROLES_PRODUCTIZATION(state, payload) {
      state.filterStatusByRolesProductization = payload.Data;
    },
    async GET_STATUS_BY_CUSTOMER_ROLES(state,payload){
      state.statusByCustomerRoles = payload.Data;
    },    
    async GET_STATUS_BY_CUSTOMER_ROLES_INVENDO(state,payload){
      state.statusByCustomerRolesInvendo = payload.Data;
    }, 
    async GET_STATUS_BY_CUSTOMER_ROLES_PRODUCTIZATION(state,payload){
      state.statusByCustomerRolesProductization = payload.Data;
    },         
    async SET_EVALUATION_DATA(state, payload) {
      state.evaluationData = [];
      payload.forEach(async (element) => {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
      });
      state.evaluationData.push(...payload);
    },
    SET_EVALUATION_DATA_TOTAL_RECORDS(state, payload) {
      state.evaluationTotalRecords = payload;
    },
    DELETE_EVALUATION_DATA(state) {
      state.evaluationData = [];
    },
    async SET_INVENDO_DATA(state, payload) {
      state.invendoData = [];
      payload.forEach(async (element) => {
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
      });
      state.invendoData.push(...payload);
    },
    SET_INVENDO_DATA_TOTAL_RECORDS(state, payload) {
      state.invendoTotalRecords = payload;
    },
    DELETE_INVENDO_DATA(state) {
      state.invendoData = [];
    },
    async SET_PRODUCTIZATION_DATA(state, payload) {
      state.productizationData = [];
      payload.forEach(async (element) => {
        if (element.UserPictureId != 0) {
          const res = await upload.fetchProfileImage(
            parseInt(element.UserPictureId)
          );
          if (res.Data.DownloadBinary) {
            element.UserProfileImage = res.Data.DownloadBinary;
          } else {
            element.UserProfileImage = null;
          }
        } else {
          element.UserProfileImage = null;
        }
        element.CreatedOnUtc = updateDate(element.CreatedOnUtc);
        element.ModifiedDateUtc = updateDate(element.ModifiedDateUtc);
        element.PendingProcessDate = updateDate(element.PendingProcessDate);
        element.ProjectStartDate = updateDate(element.ProjectStartDate);
        element.SuggestionDate = updateDate(element.SuggestionDate);
        element.SuggestionExecutiveProcessDate = updateDate(
          element.SuggestionExecutiveProcessDate
        );
        element.Comments.forEach(async (comment) => {
          comment.CreatedOnUtc = updateDate(comment.CreatedOnUtc);
          if (comment.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(comment.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              comment.UserProfileImage = res.Data.DownloadBinary;
            } else {
              comment.UserProfileImage = null;
            }
          } else {
            comment.UserProfileImage = null;
          }
        });
        element.Likes.forEach(async (item) => {
          if (item.UserPictureId != 0) {
            const res = await upload.fetchProfileImage(
              parseInt(item.UserPictureId)
            );
            if (res.Data.DownloadBinary) {
              item.UserProfileImage = res.Data.DownloadBinary;
            } else {
              item.UserProfileImage = null;
            }
          } else {
            item.UserProfileImage = null;
          }
          if (item.UserId == store.getters["user/user"].Data.UserId) {
            element.IsLiked = true;
          } else {
            element.IsLiked = false;
          }
        });
      });
      state.productizationData.push(...payload);
    },
    SET_PRODUCTIZATION_DATA_TOTAL_RECORDS(state, payload) {
      state.productizationTotalRecords = payload;
    },
    DELETE_PRODUCTIZATION_DATA(state) {
      state.productizationData = [];
    },
    SET_SLIDER_PICTURE(state, payload) {
      state.sliderPicture = payload;
    },
    GET_CANCEL_REASOSNS_LIST(state, payload) {
      state.cancelReasons = payload;
    },
  },
  actions: {
    async setData(vuexContext) {
      const loginUserId = store.getters["user/user"].Data.UserId;

      const res = await suggestionService.fetchCompanies(loginUserId);
      vuexContext.commit("SET_DATA", res.Data);
    },
    deleteCompaniesData(vuexContext) {
      vuexContext.commit("DELETE_DATA");
    },
    async getValueCurrentsData(vuexContext) {
      const companyId = store.getters["user/user"].Data.CompanyId;
      const companyGroupId = store.getters["user/user"].Data.GroupCompanyId;

      const res = await suggestionService.fetchCurrent(
        companyId,
        companyGroupId
      );
      vuexContext.commit("GET_VALUE_CURRENTS_DATA", res.Data);
    },
    async getStatusByRoles(vuexContext, data) {
      const { suggestionTypeId, companyId, loginUserId } = data;

      const body = {
        companyId,
        typeId: suggestionTypeId,
      };
      loginUserId ? (body.LoginUserId = loginUserId) : "";
      const res = await suggestionService.fetchGetStatusByRoles(body);
      vuexContext.commit("GET_STATUS_BY_ROLES", res);
    },
    async getStatusByRolesInvendo(vuexContext, data) {
      const { suggestionTypeId, companyId, loginUserId } = data;

      const body = {
        companyId,
        typeId: suggestionTypeId,
      };
      loginUserId ? (body.LoginUserId = loginUserId) : "";

      const res = await suggestionService.fetchGetStatusByRoles(body);
      vuexContext.commit("GET_STATUS_BY_ROLES_INVENDO", res);
    },
    async getStatusByRolesProductization(vuexContext, data) {
      const { suggestionTypeId, companyId, loginUserId } = data;

      const body = {
        companyId,
        typeId: suggestionTypeId,
      };
      loginUserId ? (body.LoginUserId = loginUserId) : "";

      const res = await suggestionService.fetchGetStatusByRoles(body);
      vuexContext.commit("GET_STATUS_BY_ROLES_PRODUCTIZATION", res);
    },
    async getFilterStatusByRoles(vuexContext, data) {
      const { suggestionTypeId, companyId, loginUserId } = data;

      const body = {
        companyId,
        typeId: suggestionTypeId,
      };
      loginUserId ? (body.LoginUserId = loginUserId) : "";
      const res = await suggestionService.fetchGetFilterStatusByRoles(body);
      vuexContext.commit("GET_FILTER_STATUS_BY_ROLES", res);
    },   
    async getFilterStatusByRolesInvendo(vuexContext, data) {
      const { suggestionTypeId, companyId, loginUserId } = data;

      const body = {
        companyId,
        typeId: suggestionTypeId,
      };
      loginUserId ? (body.LoginUserId = loginUserId) : "";

      const res = await suggestionService.fetchGetFilterStatusByRoles(body);
      vuexContext.commit("GET_FILTER_STATUS_BY_ROLES_INVENDO", res);
    },
    async getFilterStatusByRolesProductization(vuexContext, data) {
      const { suggestionTypeId, companyId, loginUserId } = data;

      const body = {
        companyId,
        typeId: suggestionTypeId,
      };
      loginUserId ? (body.LoginUserId = loginUserId) : "";

      const res = await suggestionService.fetchGetFilterStatusByRoles(body);
      vuexContext.commit("GET_FILTER_STATUS_BY_ROLES_PRODUCTIZATION", res);
    },  
    async getStatusByCustomerRoles(vuexContext, data) {
      const { suggestionTypeId, companyId, loginUserId } = data;

      const body = {
        companyId,
        typeId: suggestionTypeId,
      };
      loginUserId ? (body.LoginUserId = loginUserId) : "";
      const res = await suggestionService.fetchGetStatusByCustomerRoles(body);
      vuexContext.commit("GET_STATUS_BY_CUSTOMER_ROLES", res);
    },    
    async getStatusByCustomerRolesInvendo(vuexContext, data) {
      const { suggestionTypeId, companyId, loginUserId } = data;

      const body = {
        companyId,
        typeId: suggestionTypeId,
      };
      loginUserId ? (body.LoginUserId = loginUserId) : "";
      const res = await suggestionService.fetchGetStatusByCustomerRoles(body);
      vuexContext.commit("GET_STATUS_BY_CUSTOMER_ROLES_INVENDO", res);
    },  
    async getStatusByCustomerRolesProductization(vuexContext, data) {
      const { suggestionTypeId, companyId, loginUserId } = data;

      const body = {
        companyId,
        typeId: suggestionTypeId,
      };
      loginUserId ? (body.LoginUserId = loginUserId) : "";
      const res = await suggestionService.fetchGetStatusByCustomerRoles(body);
      vuexContext.commit("GET_STATUS_BY_CUSTOMER_ROLES_PRODUCTIZATION", res);
    },             
    async setEvaluationData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        searchKeyword,
        companyId,
        suggestionNumber,
        valueCurrentId,
        suggestionStatuses,
        createdUserId,
        startDate,
        endDate,
        loginUserId,
        isAdminPage,
        filterReset
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SearchKeyword: searchKeyword,
        CompanyId: companyId,
        SuggestionNumber: suggestionNumber,
        ValueCurrentId: valueCurrentId,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        StartDate: startDate,
        EndDate: endDate,
        LoginUserId: loginUserId,
        IsAdminPage: isAdminPage,
        filterReset: filterReset
      };
      const res = await suggestionService.fetchPagedSuggestion(body);
      res.Data && vuexContext.commit("SET_EVALUATION_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit(
          "SET_EVALUATION_DATA_TOTAL_RECORDS",
          res.Data.TotalRecords
        );
    },
    deleteEvaluationData(vuexContext) {
      vuexContext.commit("DELETE_EVALUATION_DATA");
    },
    async setInvendoData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        searchKeyword,
        suggestionTypes,
        companyId,
        groupCompanyId,
        suggestionNumber,
        valueCurrentId,
        suggestionStatuses,
        createdUserId,
        startDate,
        endDate,
        loginUserId,
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SearchKeyword: searchKeyword,
        SuggestionTypes: suggestionTypes,
        CompanyId: companyId,
        CompanyGroupId: groupCompanyId,
        SuggestionNumber: suggestionNumber,
        ValueCurrentId: valueCurrentId,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        StartDate: startDate,
        EndDate: endDate,
        LoginUserId: loginUserId,
      };
      const res = await suggestionInvendoService.fetchInvendoPagedSuggestion(
        body
      );
      res.Data && vuexContext.commit("SET_INVENDO_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit(
          "SET_INVENDO_DATA_TOTAL_RECORDS",
          res.Data.TotalRecords
        );
    },
    deleteInvendonData(vuexContext) {
      vuexContext.commit("DELETE_INVENDO_DATA");
    },
    async setProductizationData(vuexContext, data) {
      const {
        pageSize,
        pageIndex,
        searchKeyword,
        companyId,
        suggestionNumber,
        valueCurrentId,
        suggestionStatuses,
        createdUserId,
        startDate,
        endDate,
        loginUserId,
        isAdminPage,
      } = data;
      const body = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SearchKeyword: searchKeyword,
        CompanyId: companyId,
        SuggestionNumber: suggestionNumber,
        ValueCurrentId: valueCurrentId,
        SuggestionStatuses: suggestionStatuses,
        CreatedUserId: createdUserId,
        StartDate: startDate,
        EndDate: endDate,
        LoginUserId: loginUserId,
        IsAssignment: false,
        IsAdminPage: isAdminPage,
      };
      const res = await suggestionRdService.fetchPagedSuggesitonRD(body);
      res.Data && vuexContext.commit("SET_PRODUCTIZATION_DATA", res.Data.Data);
      res.Data &&
        vuexContext.commit(
          "SET_PRODUCTIZATION_DATA_TOTAL_RECORDS",
          res.Data.TotalRecords
        );
    },
    deleteProductizationData(vuexContext) {
      vuexContext.commit("DELETE_PRODUCTIZATION_DATA");
    },
    async getSliderPicture(vuexContext) {
      const companyId = store.getters["user/user"].Data.CompanyId;
      const res = await suggestionService.fetchSliderPicture(companyId);
      vuexContext.commit("SET_SLIDER_PICTURE", res.Data);
    },
    async getCancelReasons(vuexContext, data) {
      const { loginUserId, statusId } = data;
      const formdata = new FormData();
      formdata.append("loginUserId", loginUserId);
      formdata.append("statusId", statusId);
      const res = await suggestionService.fetchGetCancelReasons(formdata);
      vuexContext.commit("GET_CANCEL_REASOSNS_LIST", res.Data);
    },
    async createNotify(vuexContext, payload) {
      const res = await suggestionService.fetchCreateNotify(payload);
      return res;
    },
  },
};
