import { BASE_URL, responseHandler, bearerAuthHeader } from "./helpers";

export const upload = {
  fetchProfileImage,
  fetchUpluadFile,
  fetchDownloadFile,
  fetchDeleteFile,
};

async function fetchProfileImage(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...(await bearerAuthHeader()),
    },
  };
  const result = await fetch(
    `${BASE_URL}/upload/downloadpicture/${id}`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchUpluadFile(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(`${BASE_URL}/upload/uploadfile`, requestOptions);

  return responseHandler(result);
}
async function fetchDownloadFile(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
  };
  const result = await fetch(
    `${BASE_URL}/upload/downloadfile/${id}`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchDeleteFile(id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(await bearerAuthHeader()),
    },
  };
  const result = await fetch(
    `${BASE_URL}/upload/deletefile/${id}`,
    requestOptions
  );

  return responseHandler(result);
}
