import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./state/store";

import VueMask from "v-mask";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import { PublicClientApplication } from "@azure/msal-browser";
import { REDIRECT_URL } from "./services/helpers";
import moment from "moment";
Vue.config.productionTip = false;

const msalConfig = {
  auth: {
    clientId: "65238244-adcf-4cd3-9e3b-325584c403e7",
    authority: "https://login.microsoftonline.com/common/",
    redirectUri: REDIRECT_URL,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

Vue.prototype.$msalInstance = new PublicClientApplication(msalConfig);
Vue.prototype.$moment = moment;

Vue.use(BootstrapVue);
Vue.use(VueToast);
Vue.use(IconsPlugin);
Vue.use(VueMask);

require("moment-precise-range-plugin");

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
