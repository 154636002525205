<template>
  <router-view></router-view>
</template>

<script>
export default {
  //TODO 404-tools-icon.png bulunamadi
  name: "App",
  components: {},
};
</script>

<style>
@import "./assets/styles/css/index.css";
</style>
