/**
 * 
 * @param {string | number} id 
 * @returns string
 */
export const userStatus = (id) => {
  return id == "1"
    ? "Sponsor"
    : id == "2"
    ? "Ekip Üyesi"
    : id == "3"
    ? "Ekip Lideri"
    : id == "4"
    ? "Değer Akım Yöneticisi"
    : id == "5"
    ? "Proje Çalışanı"
    : "";
};
