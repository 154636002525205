/**
 * 
 * @param {string | number} id 
 * @returns string
 */
export const cancelReason = (id) => {
  return id == "1"
    ? "Hata/sorun Bildirimi"
    : id == "2"
    ? "Daha Önce İletilmiş Fikir"
    : id == "3"
    ? "Fikir Platformu Kapsamında Değerlendirilmemektedir"
    : id == "4"
    ? "Projelendirilen/Mevcut Uygulama"
    : id == "5"
    ? "Product Board Kararı"
    : id == "6"
    ? "Fikir Sahibi Çekildi"
    : id == "7"
    ? "Fayda-Maliyet Oranı Düşük"
    : id == "8"
    ? "Teknik/İşlevsel/Süreçsel Olarak Uygun Değil"
    : "";
};
