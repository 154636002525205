import { suggestionService } from "../../services";
import store from "../store";
export const user = {
  namespaced: true,
  state: {
    user: null,
    users: null,
    getTo: "",
  },
  getters: {
    user(state) {
      return state.user;
    },
    getUsers(state) {
      return state.users;
    },
    getTo(state) {
      return state.getTo;
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    async GET_USERS(state) {
      const res = await suggestionService.fetchGetUsers(
        store.getters["user/user"].Data.CompanyId
      );
      state.users = res.Data;
    },
    SET_TO(state, payload) {
      state.getTo = payload;
    },
  },
  actions: {
    setUser(vuexContext, data) {
      vuexContext.commit("SET_USER", data);
    },
    getUsers(vuexContext) {
      vuexContext.commit("GET_USERS");
    },
    setTo(vuexContext, data) {
      vuexContext.commit("SET_TO", data);
    },
  },
};
