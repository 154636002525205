import { BASE_URL, responseHandler } from "./helpers";

export const notiflowService = {
  fetchSendOTP,
  fetchLoginWithOTP,
  fetchTurkuazLogin,
};
async function fetchSendOTP(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const result = await fetch(
    `${BASE_URL}/notiflow/SendOTP?phone=${body}`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchLoginWithOTP(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...body }),
  };
  const result = await fetch(
    `${BASE_URL}/notiflow/loginwithotp`,
    requestOptions
  );

  return responseHandler(result);
}
async function fetchTurkuazLogin(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      UserName: body.userName,
      Password: body.password,
    }),
  };
  const result = await fetch(
    `${BASE_URL}/notiflow/turkuazlogin`,
    requestOptions
  );

  return responseHandler(result);
}
